import {Injectable} from '@angular/core';
import {ApiGatewayService} from "../../services/api-gateway.service";
import {IUserPassword} from "./user-password.interface";
import {Observable, Subject, throwError} from "rxjs";
import {BaseTableService} from "../../common/base-table/base-table.service";
import {IPage} from "../../common/page.interface";
import {IUserMfaRequest} from "./dto/user-mfa-request.interface";
import {IResourceDownload} from "../../common/resource-download.interface";

@Injectable({
    providedIn: 'root'
})
export class UsersService extends BaseTableService {
    name = 'Usuarios';
    _applyLastFilter: boolean;
    _lastFilter: any;

    _onAddUserModalClose: Subject<any> = new Subject<any>();

    constructor(private api: ApiGatewayService) {
        super()
    }

    getAll() {
        return this.api.get<any>('/users');
    }

    addUser(request: any) {
        return this.api.post<void>('/users', request);
    }

    removeUser(username: string) {
        return this.api.delete<void>(`/users/${encodeURIComponent(username)}`);
    }

    updatePassword(username: string, password: string) {
        return this.api.patch<any>(`/public/users/${encodeURIComponent(username)}`, {password});
    }


    get onAddUserModalClose(): Subject<any> {
        return this._onAddUserModalClose;
    }

    validatePassword(username: string, newPassword: string) {
        return this.api.post(`/public/users/${encodeURIComponent(username)}/password/validate`, {password: newPassword});
    }

    getAllPageable(pageNumber: number, pageSize: number, filters?: any): Observable<IPage<any>> {
        return new Observable<IPage<any>>();
    }

    getDetail(id: string): Observable<any> {
        return new Observable<any>();
    }

    getAllPageableById(pageNumber: number, pageSize: number, id: string, filters?: any): Observable<IPage<any>> {
        return new Observable<IPage<any>>();
    }

    signOutSession() {
        return this.api.get('/users/session/sign-out');
    }

    getReport(filters: any): Observable<any> {
        return new Observable();
    }

    getCurrentUser() {
        return this.api.get('/users/session');
    }

    updateMFA(body: IUserMfaRequest) {
        return this.api.patch('/users/mfa/disable', body);
    }

    override downloadResource(resourceName: string): Observable<IResourceDownload> {
        throw new Error('Method not implemented.');
    }
}
